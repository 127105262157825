import { BoxProps } from '@supriyalele/types'
import React from 'react'
import useTypedSelector from '../../hooks/useTypedSelector'

import Button from '../Button'

type Props = BoxProps & {}

const ButtonCheckout = (props: Props) => {
  const { ...boxProps } = props

  // Store: redux
  const checkout = useTypedSelector(state => state.store?.checkout)
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )

  // Callbacks
  const handleClick = () => {
    window.location.href = checkout.webUrl
  }

  const hasItems = !!((checkout?.lineItems?.edges?.length || 0) > 0)

  return (
    <Button
      disabled={checkoutUpdating || !hasItems}
      fontSize={2}
      onClick={handleClick}
      {...boxProps}
    >
      Checkout
    </Button>
  )
}

export default ButtonCheckout
