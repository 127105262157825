import { SanityImage } from '@supriyalele/types'
import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

export const getSanityImage = (source: SanityImage) => {
  // @ts-ignore
  const client = sanityClient({
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    useCdn: true,
  })

  const builder = imageUrlBuilder(client)

  return builder.image(source)
}
