import React from 'react'
import { useDispatch } from 'react-redux'

import useTypedSelector from '../../hooks/useTypedSelector'
import { cartVisibleSet } from '../../redux/modules/system'
import { BoxProps } from '../../types'
import Box from '../Box'
import TextBody from '../TextBody'

type Props = BoxProps & {}

const ButtonCart = (props: Props) => {
  const { ...boxProps } = props

  // Redux
  const dispatch = useDispatch()
  const checkoutLineItems = useTypedSelector(
    state => state.store.checkout?.lineItems
  )

  // Callbacks
  const handleCartShow = () => {
    dispatch(cartVisibleSet(true))
  }

  // Iterate through all line items and accumulate count
  const totalItems =
    checkoutLineItems?.edges?.reduce((acc, val) => {
      acc += val?.node?.quantity
      return acc
    }, 0) || 0

  return (
    <>
      <TextBody
        as="a"
        className="underline"
        display="flex"
        fontSize={2}
        lineHeight="body"
        onClick={handleCartShow}
        userSelect="none"
        {...boxProps}
      >
        Cart
        {/* Badge */}
        {totalItems > 0 && <Box ml="5px">({totalItems})</Box>}
      </TextBody>
    </>
  )
}

export default ButtonCart
