import pluralize from 'pluralize'
import React from 'react'
import styled from 'styled-components'

import {
  CheckoutLineItemFragment,
  ProductVariantPricePair,
} from '../../types/codegen/shopify'

import Box from '../Box'
import CartQuantityStepper from '../CartQuantityStepper'
import ImageBasic from '../ImageBasic'
import PresentmentPrice from '../PresentmentPrice'
import TextBody from '../TextBody'

const Container = styled(Box)`
  :not(:last-child) {
    border-bottom: 2px solid currentColor;
  }
`

type Props = {
  lineItem: CheckoutLineItemFragment
}

const CartLineItem = (props: Props) => {
  const { lineItem } = props

  const productVariant = lineItem?.variant

  if (!productVariant) {
    return null
  }

  const productVariantPresentmentPrices: ProductVariantPricePair[] = productVariant?.presentmentPrices?.edges?.map(
    edge => edge.node
  )

  return (
    <Container overflow="hidden">
      <TextBody fontSize={2} p={2}>
        {/* Image */}
        <Box
          alignItems="center"
          // bg="#fff"
          display="flex"
          justifyContent="center"
          size="100px"
        >
          <ImageBasic src={productVariant?.image?.transformedSrc} />
        </Box>

        {/* Title */}
        <Box my={2}>
          Title:
          <br />
          {lineItem?.title}
        </Box>

        {/* Quantity and stepper */}
        <Box my={2}>
          {lineItem?.quantity && (
            <Box>
              Quantity:
              <br />
              {lineItem.quantity} {pluralize('copy', lineItem.quantity)}
            </Box>
          )}
          <CartQuantityStepper
            productVariantId={productVariant.id}
            quantity={lineItem?.quantity}
          />
        </Box>

        {/* Variant */}
        {/*
      <Box my={2}>
        Variant: <br />
        {productVariant?.title}
      </Box>
      */}

        {/* Price */}
        <Box>
          Price:
          <br />
          <PresentmentPrice
            pricePairs={productVariantPresentmentPrices}
            quantity={lineItem?.quantity}
          />
        </Box>
      </TextBody>
    </Container>
  )
}

export default CartLineItem
