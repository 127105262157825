import React from 'react'

import useTypedSelector from '../../hooks/useTypedSelector'
import {
  ProductPriceRange,
  ProductPriceRangeEdge,
} from '../../types/codegen/shopify'

import Box from '../Box'
import FormattedMoney from '../FormattedMoney/FormattedMoney'

type Props = {
  priceRange: ProductPriceRangeEdge[]
}

const PresentmentPriceRange = (props: Props) => {
  const { priceRange } = props

  // Store: redux
  const checkoutCurrency = useTypedSelector(
    state => state.store.checkout.currencyCode
  )
  const defaultCurrency = useTypedSelector(state => state.store.defaultCurrency)

  const activeCurrency = checkoutCurrency || defaultCurrency

  const currentPriceRange = priceRange?.reduce(
    (acc: ProductPriceRange | undefined, val) => {
      if (val?.node.maxVariantPrice?.currencyCode === activeCurrency) {
        acc = val.node
      }

      return acc
    },
    undefined
  )

  const hasPriceRange =
    currentPriceRange?.minVariantPrice?.amount !==
    currentPriceRange?.maxVariantPrice?.amount

  // Display 'from' if min amd max prices differ
  return (
    <>
      {currentPriceRange?.minVariantPrice && (
        <>
          {hasPriceRange && 'From '}
          <FormattedMoney price={currentPriceRange?.minVariantPrice} />
        </>
      )}
    </>
  )
}

export default PresentmentPriceRange
