import { BoxProps, SanityImage } from '@supriyalele/types'
import React from 'react'

import getSanityCroppedImageAspect from '../../utils/getSanityCroppedImageAspect'
import BoxResponsive from '../BoxResponsive'
import ImageBase from '../ImageBase'

type Props = BoxProps & {
  image: SanityImage
  srcSetSizes?: string | string[]
}

const ImageResponsive = (props: Props) => {
  const { image, srcSetSizes = '100vw', ...boxProps } = props

  if (!image) {
    return null
  }

  const aspectRatio = getSanityCroppedImageAspect(image)

  return (
    <BoxResponsive aspectRatio={aspectRatio} {...boxProps}>
      <ImageBase source={image} srcSetSizes={srcSetSizes} />
    </BoxResponsive>
  )
}

export default ImageResponsive
