import { ProductCardData } from '@supriyalele/types'
import React from 'react'

import { ProductVariantFragment } from '../../types/codegen/shopify'
import Box from '../Box'
import ButtonAddToCart from '../ButtonAddToCart'
import ImageResponsive from '../ImageResponsive'
import PortableText from '../PortableText'
import PresentmentPriceRange from '../PresentmentPriceRange'
import TextBody from '../TextBody'

type Props = {
  product: ProductCardData
}

const ProductCard = (props: Props) => {
  const { product } = props

  const sanity = product?.sanity
  const shopify = product?.shopify
  const priceRange = shopify?.presentmentPriceRanges?.edges

  const variants:
    | undefined
    | ProductVariantFragment[] = shopify?.variants?.edges?.map(
    edge => edge.node
  )

  const defaultVariant = variants ? variants[0] : undefined

  return (
    <Box>
      {/* Image */}
      {sanity?.image && (
        <Box maxWidth="800px" mb={3} mt={[3, null, 0]}>
          <ImageResponsive image={sanity?.image} />
        </Box>
      )}

      <Box maxWidth="350px">
        {/* Body */}
        {sanity?.body && <PortableText blocks={sanity.body} />}

        {/* Price range */}
        <TextBody>
          {priceRange && (
            <Box>
              <PresentmentPriceRange priceRange={priceRange} /> + postage
            </Box>
          )}

          {/* Add to cart / sold out */}
          {shopify?.availableForSale && defaultVariant ? (
            <ButtonAddToCart productVariant={defaultVariant} />
          ) : (
            <>Sold out</>
          )}
        </TextBody>
      </Box>
    </Box>
  )
}

export default ProductCard
