import React from 'react'

import useTypedSelector from '../../hooks/useTypedSelector'
import { ProductVariantPricePair } from '../../types/codegen/shopify'
import Box from '../Box'
import FormattedMoney from '../FormattedMoney/FormattedMoney'

type Props = {
  pricePairs: ProductVariantPricePair[]
  quantity?: number
}

const PresentmentPrice = (props: Props) => {
  const { pricePairs, quantity = 1 } = props

  // Store: redux
  const checkoutCurrency = useTypedSelector(
    state => state.store.checkout.currencyCode
  )
  const defaultCurrency = useTypedSelector(state => state.store.defaultCurrency)

  const activeCurrency = checkoutCurrency || defaultCurrency

  const pricePair = pricePairs.find(
    pair => pair.price.currencyCode === activeCurrency
  )

  if (!pricePair) {
    return null
  }

  return (
    <Box>
      <FormattedMoney
        compareAtPrice={pricePair?.compareAtPrice}
        price={pricePair?.price}
        quantity={quantity}
      />
    </Box>
  )
}

export default PresentmentPrice
