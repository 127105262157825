import {
  ProductCardData,
  SanityBlock,
  SanityImage,
  SanityPageHome,
} from '@supriyalele/types'
import { GetStaticProps } from 'next'
import React from 'react'

import Box from '../components/Box'
import BoxMotion from '../components/BoxMotion'
import ButtonCart from '../components/ButtonCart'
import Cart from '../components/Cart'
import ImageResponsive from '../components/ImageResponsive'
import Logo from '../components/Logo'
import PortableTextLarge from '../components/PortableTextLarge'
import ProductCard from '../components/ProductCard'
import SEO from '../components/SEO'
import { pageHome } from '../lib/sanityFragments'
import getActiveProducts from '../services/getActiveProducts'

type Props = {
  data: {
    footer?: SanityBlock[]
    images?: SanityImage[]
    products: ProductCardData[]
  }
}

const HomePage = (props: Props) => {
  const { data } = props

  return (
    <>
      {/* Page content */}
      <BoxMotion
        animate={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        transition={{
          delay: 1.5,
          duration: 1,
        }}
      >
        {/* Cart */}
        <Cart />

        {/* Cart button */}
        <Box p={2} position="fixed" right={0} top={0} zIndex="header">
          <ButtonCart />
        </Box>

        {/* Products */}
        <Box>
          {data?.products?.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </Box>

        {/* Images */}
        <Box maxWidth="800px" mb={3} mt={5}>
          {data?.images?.map((image, index) => (
            <ImageResponsive image={image} key={index} mb={3} />
          ))}
        </Box>

        {/* Logo */}
        <Box maxWidth="102px" pt={6} userSelect="none">
          <Logo />
        </Box>

        {/* Footer */}
        {data?.footer && <PortableTextLarge blocks={data.footer} />}
      </BoxMotion>

      {/* SEO */}
      <SEO />
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const { sanityClientRead } = require('../lib/sanity')
  const sanityResult: SanityPageHome = await sanityClientRead.fetch(pageHome())

  // Strip empty objects from array
  const sanityProducts = sanityResult?.products?.filter(
    v => Object.keys(v).length !== 0
  )

  const activeProducts = await getActiveProducts(sanityProducts)

  return {
    props: {
      data: {
        ...sanityResult,
        products: activeProducts,
      },
    },
    revalidate: 60, // seconds
  }
}

export default HomePage
