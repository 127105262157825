import React from 'react'
import useTypedSelector from '../../hooks/useTypedSelector'

import Box from '../Box'
import CartLineItem from '../CartLineItem'
import TextBody from '../TextBody'

type Props = {}

const CartLineItems = (props: Props) => {
  // Store: redux
  const checkout = useTypedSelector(state => state.store?.checkout)

  const lineItems = checkout?.lineItems?.edges?.map(lineItem => lineItem.node)

  return (
    <Box>
      {lineItems && lineItems?.length > 0 ? (
        lineItems?.map(lineItem => (
          <CartLineItem lineItem={lineItem} key={lineItem.id} />
        ))
      ) : (
        <TextBody fontSize={2} p={2}>
          Your cart is empty
        </TextBody>
      )}
    </Box>
  )
}

export default CartLineItems
