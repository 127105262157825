import React from 'react'
import { useDispatch } from 'react-redux'

import useTypedSelector from '../../hooks/useTypedSelector'
import { storeLineItemAdd } from '../../redux/modules/store'
import { BoxProps } from '../../types'
import { ProductVariantFragment } from '../../types/codegen/shopify'
import Button from '../Button'

type Props = BoxProps & {
  productVariant: ProductVariantFragment
}

const ButtonAddToCart = (props: Props) => {
  const { productVariant, ...boxProps } = props

  // Store: redux
  const dispatch = useDispatch()
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )
  const lineItemAdding = useTypedSelector(state => state.store.lineItemAdding)

  // Callbacks
  const handleClick = () => {
    dispatch(storeLineItemAdd(productVariant?.id, 1))
  }

  const availableForSale = productVariant.availableForSale

  return (
    <Button
      disabled={checkoutUpdating || lineItemAdding || !availableForSale}
      onClick={handleClick}
      {...boxProps}
    >
      <em>{availableForSale ? 'Purchase' : 'Sold out'}</em>
    </Button>
  )
}

export default ButtonAddToCart
