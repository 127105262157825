import React from 'react'
import { LayoutProps } from 'styled-system'

import useImageLoaded from '../../hooks/useImageLoaded'
import Box from '../Box'

type Props = LayoutProps & {
  src: string
}

const ImageBasic = (props: Props) => {
  const { src } = props

  const imageLoaded = useImageLoaded(src, true)

  return (
    <Box
      as="img"
      opacity={Number(imageLoaded)}
      pointerEvents="none"
      src={src}
      userSelect="none"
      width="100%"
    />
  )
}

export default ImageBasic
