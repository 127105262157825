import { BoxProps } from '@supriyalele/types'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import useClickOutside from '../../hooks/useClickOutside'
import useTypedSelector from '../../hooks/useTypedSelector'
import { cartVisibleSet } from '../../redux/modules/system'
import { MoneyV2 } from '../../types/codegen/shopify'
import Box from '../Box'
import ButtonCheckout from '../ButtonCheckout'
import CartLineItems from '../CartLineItems'
import FormattedMoney from '../FormattedMoney/FormattedMoney'
import PortableTextSmall from '../PortableTextSmall'
import TextBody from '../TextBody'

type Props = BoxProps & {}

const Cart = (props: Props) => {
  const { ...boxProps } = props

  // Refs
  const refContainer = useRef<HTMLElement>(null)
  const refLineItems = useRef<HTMLElement>(null)

  // Redux
  const dispatch = useDispatch()
  const checkout = useTypedSelector(state => state.store?.checkout)
  const cartText = useTypedSelector(state => state.system.cartText)
  const visible = useTypedSelector(state => state.system.cartVisible)

  // Callbacks
  const handleClose = () => {
    dispatch(cartVisibleSet(false))
  }

  // Close when clicked outside
  useClickOutside(refContainer, () => {
    handleClose()
  })

  // Lock body scroll when cart is open, re-enable when closed
  useEffect(() => {
    if (visible && refLineItems?.current) {
      disableBodyScroll(refLineItems.current)
    } else {
      clearAllBodyScrollLocks()
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [visible])

  if (!visible) {
    return null
  }

  return (
    <>
      {/* Overlay */}
      <Box
        bg="rgba(0, 0, 0, 0.2)"
        left={0}
        position="fixed"
        size="100%"
        top={0}
        zIndex="cart"
      />

      {/* Cart */}
      <Box
        alignItems="space-between"
        bg="background"
        borderLeft="1px solid currentColor"
        display="flex"
        flexDirection="column"
        height="100%"
        position="fixed"
        ref={refContainer}
        right={0}
        top={0}
        maxWidth="400px"
        width="100%"
        zIndex="cart"
        {...boxProps}
      >
        {/* Header */}
        <Box
          borderBottom="1px solid currentColor"
          display="flex"
          flexShrink={0}
          justifyContent="space-between"
          p={2}
        >
          <TextBody fontSize={2}>View / Edit Cart</TextBody>
          <TextBody
            as="a"
            className="underline"
            fontSize={2}
            onClick={handleClose}
            userSelect="none"
          >
            Close
          </TextBody>
        </Box>

        {/* Line items */}
        <Box flexGrow={1} overflowY="auto" ref={refLineItems}>
          <CartLineItems />

          {cartText && (
            <PortableTextSmall
              blocks={cartText}
              borderTop="1px solid currentColor"
              p={2}
            />
          )}
        </Box>

        {/* Subtotal */}
        <Box
          alignItems="flex-start"
          borderTop="1px solid currentColor"
          display="flex"
          flexDirection="column"
          flexShrink={0}
          p={2}
        >
          <TextBody fontSize={2}>
            Subtotal:
            <br />
            <FormattedMoney price={checkout?.subtotalPriceV2 as MoneyV2} />
          </TextBody>
        </Box>

        {/* Checkout */}
        <Box
          alignItems="flex-start"
          borderTop="1px solid currentColor"
          display="flex"
          flexDirection="column"
          flexShrink={0}
          p={2}
        >
          <ButtonCheckout />
        </Box>
      </Box>
    </>
  )
}

export default Cart
