import React from 'react'
import { useDispatch } from 'react-redux'

import useTypedSelector from '../../hooks/useTypedSelector'
import { storeLineItemUpdate } from '../../redux/modules/store'
import { BoxProps } from '../../types'
import Button from '../Button'

type Props = BoxProps & {
  disabled?: boolean
  productVariantId: string
  quantity: number
}

const ButtonAddSingleQuantity = (props: Props) => {
  const { disabled, productVariantId, quantity, ...boxProps } = props

  // Store: redux
  const dispatch = useDispatch()
  const checkoutUpdating = useTypedSelector(
    state => state.store.checkoutUpdating
  )
  const lineItemRemoving = useTypedSelector(
    state => state.store.lineItemRemoving
  )
  const lineItemUpdating = useTypedSelector(
    state => state.store.lineItemUpdating
  )

  // Callbacks
  const handleUpdate = () => {
    dispatch(storeLineItemUpdate(productVariantId, quantity + 1))
  }

  return (
    <Button
      disabled={
        checkoutUpdating || disabled || lineItemRemoving || lineItemUpdating
      }
      fontSize={2}
      fontStyle="italic"
      onClick={handleUpdate}
      {...boxProps}
    >
      add
    </Button>
  )
}

export default ButtonAddSingleQuantity
