import React from 'react'

import { BoxProps } from '../../types'
import Box from '../Box'
import ButtonAddSingleQuantity from '../ButtonAddSingleQuantity'
import ButtonRemoveSingleQuantity from '../ButtonRemoveSingleQuantity'

type Props = BoxProps & {
  productVariantId: string
  quantity: number
}

const CartQuantityStepper = (props: Props) => {
  const { productVariantId, quantity, ...boxProps } = props

  return (
    <Box {...boxProps}>
      <Box>
        <ButtonAddSingleQuantity
          mr={1}
          productVariantId={productVariantId}
          quantity={quantity}
        />

        <ButtonRemoveSingleQuantity
          productVariantId={productVariantId}
          quantity={quantity}
        />
      </Box>
    </Box>
  )
}

export default CartQuantityStepper
